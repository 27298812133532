import axios from 'axios'
import qs from 'qs'

axios.defaults.timeout = 60000;
axios.defaults.retry = 3;
axios.defaults.retryDelay = 1000;

let service_url = "";
let main_url = "";
let gengyun_url = "";
let zsk_url = "";
let domain = ''
if (process.env.NODE_ENV == 'development') {
  service_url = "http://10.10.3.126:18052";
  main_url = "http://10.10.3.127:8082/";
  domain = '10.6.3.20'
  gengyun_url = '';
  zsk_url = ''
} else if (process.env.NODE_ENV == 'test') {
  service_url = "https://test.d2ssoft.com/account";
  main_url = "https://test.d2ssoft.com/";
  domain = 'test.d2ssoft.com'
  gengyun_url = 'https://test.d2ssoft.com/gengyun/';
  zsk_url = 'https://test.d2ssoft.com/kbmsWeb/'
} else if (process.env.NODE_ENV == 'production') {
  // service_url = "https://work.d2ssoft.com/account";
  // main_url = "https://work.d2ssoft.com/";
  // domain = 'work.d2ssoft.com'
  // gengyun_url = 'https://work.d2ssoft.com/gengyun/';
  // zsk_url = 'https://work.d2ssoft.com/kbmsWeb/'


  // --------------------预发布环境-------------------------
  service_url = "https://demo.d2ssoft.com/account";
  main_url = "https://demo.d2ssoft.com/";
  domain = "demo.d2ssoft.com"
  gengyun_url = 'https://demo.d2ssoft.com/gengyun/';
  zsk_url = 'https://demo.d2ssoft.com/kbmsWeb/'
  // --------------------预发布环境-------------------------
}

export {
  main_url,
  gengyun_url,
  zsk_url,
  domain
}

export const PRIVILEGES = {
  PRIVILEGE_ADMIN: "c5fd4bd184d6eb5b5be549a23fdc7f92",
  PRIVILEGE_SYSTEM: "5183b9e03501beac32aedd07262403e1",
  PRIVILEGE_USER: "9f0d4faca0b0c91d65486654fec51e4c",
  PRIVILEGE_ROLE: "6011803d53824a3700c94f2806874e86",
  PRIVILEGE_APP: "c9e3afabf2176bd60dcb1e543b397d42",
  PRIVILEGE_LOG: "c2ac43581b8a4e237e7b83ff1f822898",
  PRIVILEGE_LOG_LOGIN: "6b95637619972188f61217b375bc4b2e",
  PRIVILEGE_LOG_ST: "96c852ce227d2e887bd7f82ba2578893",
  PRIVILEGE_LOG_OPER: "b7fd518295fad09556022edfb734021e",
  PRIVILEGE_LOG_ANALYSIS: "fa521206146a5adb7a3ef27a00594384",
  PRIVILEGE_UNLOCK: "d6eebd3bd56eab5d39706c98bc2b27fd"
};

axios.interceptors.request.use(
  request => {
    let authorization = getAuthToken();
    if (authorization != null) {
      request.headers["Authorization"] = `${authorization}`;
    }
    return request;
  }, error => {
    return Promise.error(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  }, error => {
    if (error.response.status) {
      if (error.response.status == 401) {
        login();
      }
    }

    return Promise.reject(error.response);
  }
);
export const refreshToken = (params) => {
  return axios.post(`${service_url}/refreshJWT`, params);
}

export const authorization = params => {
  return axios.post(`${service_url}/serviceValidate`, qs.stringify({ service: main_url, ticket: params }));
};

export const getWxUserByCode = params => {
  return axios.post(`${service_url}/getWxUserByCode`, params);
};


export const getAuthToken = () => {
  if (window.sessionStorage) {
    let authorization = window.sessionStorage.getItem("authorizationToken");
    if (authorization != null) return authorization;
  }

  return null;
};

export const setAuthToken = (authorizationToken) => {
  if (window.sessionStorage) {
    let payload = authorizationToken.split(".")[1];
    payload = decodeURIComponent(escape(window.atob(payload.replace(/_/g, "/").replace(/-/g, "+"))));

    let user = JSON.parse(payload);
    window.sessionStorage.setItem("authorizationToken", 'Bearer ' + authorizationToken);
    // window.sessionStorage.setItem("userPrincipalName", user.userPrincipalName);
    window.sessionStorage.setItem("name", user.name);
    document.cookie = 'authorizationToken=' + authorizationToken + ';domain=' + domain + ';path=/';
    // document.cookie = 'userPrincipalName=' + user.userPrincipalName + ';domain=' + domain + ';path=/';
    document.cookie = 'name=' + user.name + ';domain=' + domain + ';path=/';
  }
}

export const getUser = () => {
  if (window.sessionStorage) {
    let userPrincipalName = window.sessionStorage.getItem("userPrincipalName");
    let name = window.sessionStorage.getItem("name");

    if (userPrincipalName != null) return { userPrincipalName: userPrincipalName, name: name };
  }

  return { userPrincipalName: '', name: '' };
}

export const getPrivileges = (userPrincipalName, callback) => {
  axios.post(`${service_url}/portal/role/getUserPrivileges`, qs.stringify({ userId: userPrincipalName })).then((response) => {
    let result = response.data;
    if (result.success) {
      let privileges = ',';
      result.data.map((p) => {
        privileges += p + ','
      });
      window.sessionStorage.setItem("privileges", privileges);
    } else {
      window.sessionStorage.setItem("privileges", '');
    }

    if (callback) callback();
  });
}

export const hasPrivilege = (privilege) => {
  if (window.sessionStorage) {
    let privileges = window.sessionStorage.getItem("privileges");
    if (privileges == null) return false;

    return privileges.indexOf(',' + privilege + ',') >= 0;
  }

  return false;
}

export const login = (renew) => {
  if (renew) {
    window.location.href = service_url + "/login?service=" + encodeURIComponent(main_url) + "&renew=true";
  } else {
    window.location.href = service_url + "/login?service=" + encodeURIComponent(main_url);
  }
}

export const logout = () => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem("authorizationToken");
    window.sessionStorage.removeItem("refreshToken");
    window.sessionStorage.removeItem("userPrincipalName");
    window.sessionStorage.removeItem("name");
    // window.sessionStorage.removeItem("privileges");
  }
  const clearCookies = document.cookie.split(';')
  clearCookies.forEach(cookie => document.cookie =
    cookie.replace(/^ +/, '')
      .replace(/=.*/, `=;domain=${domain};path=/`));
  window.location.href = service_url + "/logout?url=" + encodeURIComponent(main_url);
}

export const getServiceUrl = () => { return service_url; };

export const findUser = params => { return axios.post(`${service_url}/account/manage/findUser`, params); };
export const editUser = params => { return axios.post(`${service_url}/account/manage/editUser`, params); };
export const getUsername = () => { if (window.sessionStorage) return window.sessionStorage.getItem("username"); };
export const getPassword = () => { if (window.sessionStorage) return window.sessionStorage.getItem("password"); };
export const generateST = params => { return axios.post(`${service_url}/account/cas/generateST`, params); };
export const findLockedUser = params => { return axios.post(`${service_url}/account/manage/findLockedUser`, params); };
export const unlockUser = params => { return axios.post(`${service_url}/account/manage/unlockUser`, params); };
export const getUserInfo = params => { return axios.post(`${service_url}/account/manage/getUserInfo`, params); };

export const findApp = params => { return axios.post(`${service_url}/portal/app/find`, params); };
export const findAllApp = () => { return axios.post(`${service_url}/portal/app/findAllEnabled`); };
export const editApp = params => { return axios.post(`${service_url}/portal/app/edit`, params); };
export const addApp = params => { return axios.post(`${service_url}/portal/app/add`, params); };
export const deleteApp = params => { return axios.post(`${service_url}/portal/app/delete`, params); };
export const appProxy = params => { return axios.post(`${service_url}/portal/app/proxy`, params); };

export const findRole = () => { return axios.post(`${service_url}/portal/role/findAll`); };
export const findPrivilege = () => { return axios.post(`${service_url}/portal/role/privileges`); };
export const addRole = params => { return axios.post(`${service_url}/portal/role/add`, params); };
export const editRole = params => { return axios.post(`${service_url}/portal/role/edit`, params); };
export const deleteRole = params => { return axios.post(`${service_url}/portal/role/delete`, params); };
export const getRoleDefine = params => { return axios.post(`${service_url}/portal/role/roleDefine`, params); };
export const saveRoleDefine = params => { return axios.post(`${service_url}/portal/role/saveRoleDefine`, params); };

export const getUserRole = params => { return axios.post(`${service_url}/portal/role/userRole`, params); };
export const saveUserRole = params => { return axios.post(`${service_url}/portal/role/saveUserRole`, params); };

export const findLog = params => { return axios.post(`${service_url}/portal/log/findLog`, params); };
export const findST = params => { return axios.post(`${service_url}/account/cas/findST`, params); };

export const countLogByDay = params => { return axios.post(`${service_url}/portal/log/countLogByDay`, params); };
export const countSTByDay = params => { return axios.post(`${service_url}/account/cas/countSTByDay`, params); };

export default axios